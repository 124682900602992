import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import withPageEntry from '../../withPageEntry';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import {formatDateStr} from '../../Utils/TimeUtil';

function FindDetail(props) {
  const params = qs.parse(props.location.search);
  const {id, type} = params;
  const collection = type === 'job' ? 'Job' : 'Room';

  const [record, setRecord] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        let record = await JStorage.fetchOneDocument('review_request', {
          id,
        });
        setRecord({...record, ...record.data});
        AppActions.setLoading(false);
      } catch (err) {
        AppActions.setLoading(false);
      }
    }

    fetchData();
  }, [id, collection]);

  return (
    <Wrapper>
      {type === 'job' && record && (
        <div className="container">
          <div className="title">
            <h1>{record.name}</h1>
            <span>
              刊登日期：{formatDateStr(new Date(record.Start_Time))} ~{' '}
              {record.End_Time
                ? formatDateStr(new Date(record.End_Time))
                : formatDateStr(
                    new Date(
                      new Date(record.Start_Time).getTime() +
                        30 * 24 * 60 * 60 * 1000,
                    ),
                  )}
            </span>
          </div>
          <div className="divider" />
          <div className="content">
            <div>
              <b>負責人：</b>
              {record.chaired_lawyer || '-- --'}
            </div>
            <div>
              <b>地址：</b>
              {record.address_firm || '-- --'}
            </div>
            <div>
              <b>電郵：</b>
              {record.email || '-- --'}
            </div>
            <div>
              <b>傳真：</b>
              {record.fax || '-- --'}
            </div>
            <div>
              <b>主要業務：</b>
              {record.business || '-- --'}
            </div>
            <h2 className="title">求才條件</h2>
            {record.openings?.map((open, index) => (
              <div className="item" key={`opening-${index}`}>
                <h3 className="name">
                  {open.name} {open.numbers} 名
                </h3>
                <div>
                  <b>待遇：</b>
                  {open.treatment || '-- --'}
                </div>
                <div>
                  <b>學歷：</b>
                  {open.education || '-- --'}
                </div>
                <div>
                  <b>專長：</b>
                  {open.expertise || '-- --'}
                </div>
                <div>
                  <b>個別特質：</b>
                  {open.characteristic || '-- --'}
                </div>
                <div className="note">{open.other}</div>
              </div>
            ))}
          </div>
        </div>
      )}

      {type === 'room' && record && (
        <div className="container">
          <div className="title">
            <h1>{record.name}</h1>
            <span>
              刊登日期：{formatDateStr(new Date(record.Start_Time))} ~{' '}
              {record.End_Time
                ? formatDateStr(new Date(record.End_Time))
                : formatDateStr(
                    new Date(
                      new Date(record.Start_Time).getTime() +
                        30 * 24 * 60 * 60 * 1000,
                    ),
                  )}
            </span>
          </div>
          <div className="divider" />
          <div className="content">
            <div>
              <b>負責人：</b>
              {record.chaired_lawyer || '-- --'}
            </div>
            <div>
              <b>地址：</b>
              {record.address_firm || '-- --'}
            </div>
            <div>
              <b>電郵：</b>
              {record.email || '-- --'}
            </div>
            <div>
              <b>成員：</b>
              律師 {record.member_lawyer || 0} 名、實習律師{' '}
              {record.member_apprentice || 0} 名、助理{' '}
              {record.member_assistant || 0} 名
            </div>
            <div>
              <b>坪數：</b>
              {record.square_feet || '-- --'}
            </div>

            <div className="item">
              <h3 className="name">辦公室描述</h3>
              <div>
                <div>{record.description}</div>
              </div>
              <div>{record.expense || '-- --'}</div>
            </div>
            <div className="item">
              <h3 className="name">辦公室設備</h3>
              <div>{record.equipment || '-- --'}</div>
            </div>
            <div className="item">
              {record.images?.map((ele, idx) => (
                <img className="office" alt={`office-${idx}`} src={ele.image} />
              ))}
            </div>
            {record.show_richtext && (
              <div dangerouslySetInnerHTML={{__html: record.richtext?.html}} />
            )}
          </div>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0px;
  max-width: var(--contentMaxWidth);
  width: 100%;
  min-height: 100vh;
  padding: 40px 20px;

  & > .container {
    margin: 0 auto;
    max-width: var(--containerMaxWidth900px);
    width: 100%;
    padding: 20px 40px;

    & > div.title {
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      & > h1 {
        font-weight: 600;
      }
    }

    & > div.divider {
      background-color: var(--primaryColor);
      width: 100%;
      height: 8px;
    }

    & > div.content {
      padding-top: 20px;
      line-height: 1.8rem;

      & > div {
        font-size: 1rem;
      }

      & > .title {
        margin: 15px 0px;
      }

      & > .item {
        margin: 15px 0px;

        & > .name {
          font-weight: 600;
          color: var(--secondColor);
        }

        & > .note {
          margin: 5px 0px;
        }

        & > .office {
          margin-bottom: 20px;
          width: 100%;
          height: auto;
        }
      }
    }
  }
`;

export default withPageEntry(FindDetail);
